body {
  font-size: 1.4em;
  color: #212121;
  font-family: "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro",
    游ゴシック体, "Yu Gothic", YuGothic, メイリオ, Meiryo, Osaka,
    "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  height: 100%;
  line-height: 1.8;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

@media screen and (min-width: 992px) {
  body {
    min-width: 960px;
  }
}

.oldie body {
  min-width: 960px;
}

p {
  margin-top: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.oldie img {
  width: auto;
}

address {
  font-style: normal;
}

figure {
  margin: 0;
}

dd {
  margin-left: 0;
}

ol,
ul {
  padding-left: 1.5em;
}

li {
  margin-bottom: 0.5em;
}

section {
  margin-bottom: 60px;
}

iframe {
  border: 1px solid #ccc;
}

/**
 * メインコンテンツの親
 */
.contents {
  padding-top: 70px;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 80px);
}

/**
 * Google Map
 */
.gmap {
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

@media screen and (max-width: 767px) {
  .gmap {
    height: 340px;
  }
}

@media screen and (max-width: 640px) {
  .gmap {
    height: 260px;
  }
}

@media print {
  a[href]:after {
    display: none;
  }

  abbr[title]:after {
    display: none;
  }
}

/**
 * リンクフォーマット
 */
a {
  color: #212121;
  text-decoration: underline;
}

a:active,
a:hover,
a:focus {
  color: #212121;
  text-decoration: none;
}

a.rev {
  text-decoration: none;
}

a.rev:active,
a.rev:hover,
a.rev:focus {
  text-decoration: underline;
}

a:hover > img {
  opacity: 0.65;
}

a.link-noline,
.link-noline a {
  text-decoration: none;
}

a.link-noline:hover,
.link-noline a:hover {
  text-decoration: underline;
}

/**
 * URLを省略させる
 */
a.link-ikaryaku {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 180px;
  position: relative;
  padding-right: 1em;
}

a[target="_blank"].link-ikaryaku:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f08e";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.5em;
}

@media screen and (max-width: 640px) {
  a.link-ikaryaku {
    max-width: 240px;
  }
}

/**
*  ページ見出し前方に◯をつける
*/
.heading-page {
  font-size: 2.4rem;
  font-weight: normal;
  margin: 30px 0;
  padding: 7px 0 7px 55px;
  position: relative;
}

.heading-page:before {
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: 24px auto;
  background-size: 24px auto;
  border: 1px solid #555;
  border-radius: 100%;
  content: "";
  height: 40px;
  width: 40px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

/**
 * ページ見出し、各ページのアイコン
 */
.heading-dashboard:before {
  background-image: url("../images/common/icon_home.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-dashboard:before {
    background-image: url("../images/common/icon_home.svg");
  }
}

.heading-edit:before {
  background-image: url("../images/common/icon_setting.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-edit:before {
    background-image: url("../images/common/icon_setting.svg");
  }
}

.heading-design:before {
  background-image: url("../images/common/icon_design.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-design:before {
    background-image: url("../images/common/icon_design.svg");
  }
}

.heading-send:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f1d9";
  text-align: center;
  line-height: 40px;
}

.heading-guest:before {
  background-image: url("../images/common/icon_guest.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-guest:before {
    background-image: url("../images/common/icon_guest.svg");
  }
}

.heading-venue:before {
  background-image: url("../images/common/icon_marker.png");
  -webkit-background-size: 20px auto;
  background-size: 20px auto;
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-venue:before {
    background-image: url("../images/common/icon_marker.svg");
  }
}

.heading-admistrator:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f183";
  text-align: center;
  line-height: 40px;
}

.heading-wedding-hall:before {
  background-image: url("../images/common/icon_wedding_chapel.png");
  -webkit-background-size: 20px auto;
  background-size: 20px auto;
}

.heading-invitation-reply-custom-fields:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f03a";
  text-align: center;
  line-height: 40px;
}

.heading-bank-account:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 20px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f19c";
  text-align: center;
  line-height: 37px;
}

.heading-participation-fees:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 23px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f157";
  text-align: center;
  line-height: 40px;
}

.heading-report:before {
  background-image: url("../images/common/icon_list.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-report:before {
    background-image: url("../images/common/icon_guest.svg");
  }
}

.heading-invitations:before {
  background-image: url("../images/common/icon_email.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .heading-invitations:before {
    background-image: url("../images/common/icon_email.svg");
  }
}

/**
 * ページ見出しの◯をなくす。
 */
.heading-none:before {
  display: none;
}

/**
 * ダッシュボート、タブ上にある見出し
 * タブとタイトルを同じ高さにするときにつかう
 */
.heading-tab {
  font-size: 2rem;
  font-weight: normal;
  margin: 0 0 -28px;
}

/**
 * セクション見出し
 */
.heading-section {
  font-size: 2rem;
  border-bottom: 1px solid #ccc;
  font-weight: normal;
  margin: 0 0 30px;
  padding: 20px 0;
}

/**
 * ゲスト管理・回答の確認ページの、
 * 現在１０人の回答がありました。
 * の部分
 */
.heading-reply {
  font-size: 2rem;
  line-height: 1.2;
  margin: 0 0 30px;
}

.heading-reply-num {
  font-size: 6rem;
}

@media screen and (max-width: 768px) {
  .heading-tab {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 640px) {
  .heading-page {
    font-size: 2.2rem;
  }

  .design-cover .design-thumbnail .design-preview {
    top: 45%;
  }
}

.btn {
  text-decoration: none;
}

.btn-transparent {
  background: transparent;
}

.btn-transparent:active,
.btn-transparent:hover,
.btn-transparent:focus {
  outline: none;
}

.btn-primary {
  background-color: #111;
  padding: 10px;
  width: 140px;
}

.btn-primary:active,
.btn-primary:hover,
.btn-primary:focus {
  background-color: #5e5e5e;
}

.btn-lg {
  padding: 18px 16px;
  width: 240px;
}

.btn .badge {
  background-color: #ff2929;
}

.btn-login {
  font-size: 1.8rem;
  border: none;
  background-color: #ee5f62;
  color: #fff;
  height: 60px;
  width: 240px;
}

.btn-login:active,
.btn-login:hover,
.btn-login:focus {
  background-color: #e93135;
  color: #fff;
}

.btn-disabled {
  background-color: #aaa;
  color: #fff;
  cursor: default;
  padding: 10px;
  width: 140px;
}

.btn-disabled:active,
.btn-disabled:hover,
.btn-disabled:focus {
  color: #fff;
}

.btn-dark {
  background-color: #555;
  color: #fff;
}

.btn-dark:active,
.btn-dark:hover,
.btn-dark:focus {
  color: #fff;
  background-color: #484848;
}

.btn-pager {
  padding-top: 20px;
  padding-bottom: 20px;
}

.btn-angle-right:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  float: right;
  margin-left: -1em;
  margin-top: 0.1em;
}

.btn-preview {
  display: inline-block;
  margin: 10px 0 0 30px;
}

.btn-registration {
  background-color: #f4f4f4;
  border: 2px solid #555;
  padding: 15px 10px;
  width: 200px;
}

.btn-registration:hover {
  border-color: #777;
  color: #777;
}

.btn-filter {
  color: #fff;
  background-color: #111;
  border: none;
  margin: 5px 0;
  padding: 10px 15px;
  position: relative;
  top: -2px;
}

.btn-filter:active,
.btn-filter:hover,
.btn-filter:focus {
  color: #fff;
  background-color: #5e5e5e;
}

@media screen and (max-width: 640px) {
  .btn-preview {
    margin-left: 10px;
  }
}

.label {
  font-size: 1.4rem;
  color: #fff;
  font-weight: normal;
  padding: 0.3em 0.8em 0.4em;
}

.label-s {
  font-size: 75%;
  padding: 0.2em 0.6em 0.3em;
}

.label-border {
  color: #212121;
  border: 1px solid #212121;
}

.label-gray {
  background-color: #bbb;
  color: #212121;
}

.label-dark {
  background-color: #555;
  color: #fff;
}

.label-attend {
  background-color: #f07477;
}

.label-absent {
  background-color: #ccc;
}

.label-hold {
  background-color: #28aeb9;
}

.label-id {
  background-color: #777;
}

.label-transparent {
  color: #212121;
  background: transparent;
}

.label-required {
  background-color: #ee5f62;
}

.label-optional {
  background-color: #eae8e6;
}

.icon-angle,
.list-angle > li {
  position: relative;
  padding-left: 0.8em;
}

.icon-angle:before,
.list-angle > li:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  position: absolute;
  left: 0;
  top: 0.4em;
}

.icon-caret,
.list-caret > li {
  position: relative;
  padding-left: 0.8em;
}

.icon-caret:before,
.list-caret > li:before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0da";
  position: absolute;
  left: 0;
  top: 0.4em;
}

/**
 * リスト
 */
.list,
.list-angle,
.list-caret,
.list-send,
.side-nav,
.footer-nav {
  list-style: none;
  margin-top: 0;
  padding-left: 0;
}

@media screen and (max-width: 767px) {
  .list-sm-harf > li {
    width: 100%;
  }
}

.list-send > li {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: 15px center;
  -webkit-background-size: 36px;
  background-size: 36px;
  margin-right: 10px;
  width: 23%;
  vertical-align: top;
}

.list-send > li > a {
  border: 1px solid #aaa;
  border-radius: 5px;
  display: block;
  padding: 10px 10px 10px 65px;
  height: 73px;
  text-decoration: none;
}

.list-send > li > a:active,
.list-send > li > a:hover,
.list-send > li > a:focus {
  border-color: #ee5f62;
}

.list-send-email {
  background-image: url("../images/customer/icon_email_send.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .list-send-email {
    background-image: url("../images/customer/icon_email_send.svg");
  }
}

.list-send-sms {
  background-image: url("../images/customer/icon_sms.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .list-send-sms {
    background-image: url("../images/customer/icon_sms.svg");
  }
}

.pc .list-send > .list-send-sms > a {
  background-color: #bbb;
  background-color: rgba(187, 187, 187, 0.65);
  pointer-events: none;
}

.list-send-facebook {
  background-image: url("../images/customer/icon_facebook.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .list-send-facebook {
    background-image: url("../images/customer/icon_facebook.svg");
  }
}

.list-send-line {
  background-image: url("../images/customer/icon_line.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .list-send-line {
    background-image: url("../images/customer/icon_line.svg");
  }
}

.list-send > li.list-send-nolabel > a {
  padding-top: 23px;
}

@media screen and (max-width: 1200px) {
  .list-send > li {
    max-width: 260px;
    width: 45%;
  }
}
@media screen and (max-width: 640px) {
  .list-send > li {
    width: 100%;
  }
}

/**
 * インプットのフォーマット
 */
input[type="text"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="number"] {
  font-size: 1.4rem;
  background: #f4f4f4;
  border: 1px solid #fff;
  border-radius: 4px;
  height: 3em;
  line-height: 3;
  outline: none;
  max-width: 500px;
  padding: 0 10px;
  width: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="number"]:focus {
  border-color: #ee5f62;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  font-size: 1.4rem;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  border-radius: 4px;
  outline: none;
  max-width: 640px;
  padding: 5px 10px;
  resize: vertical;
  width: 100%;
}

textarea:focus {
  border-color: #ee5f62;
}

input[type="checkbox"],
input[type="radio"] {
  background: #fff;
  cursor: pointer;
  margin-right: 0.5em;
}

/**
 * セレクトのフォーマット
 */
select {
  font-size: 1.4rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../images/common/icon_caret_bottom.png") no-repeat right center
    #f4f4f4;
  border: 1px solid #fff;
  border-right: 10px solid #f4f4f4;
  border-radius: 4px;
  height: 3em;
  line-height: 3;
  outline: none;
  padding: 0 25px 0 15px;
}

/**
 * IE Hack
 */
select {
  background-image: none\9;
  padding-right: 0\9;
}

option {
  background: #fff;
}

label {
  cursor: pointer;
  font-weight: normal;
}

label + label {
  margin-left: 20px;
}

/**
 * インプット、名前用
 */
input.input-name {
  max-width: 15em;
}

/**
 * インプットスタイル、ボーダーと白背景
 */
input.input-plane {
  border-color: #ccc;
  background-color: #fff;
}

/**
 * テーブル
 */
table tr.disabled,
table th.disabled,
table td.disabled {
  background-color: #eee;
}

.table-basic {
  border: 1px solid #ccc;
}

/**
 * 背景色、白ボーダー
 * ゲスト管理・回答の確認で使う
 */
.table-member {
  border-collapse: separate;
  background-color: #f4f4f4;
  border-radius: 5px;
}

.table-member > thead > tr > th {
  background-color: #333;
  border-bottom: none;
  color: #fff;
  font-weight: normal;
}

.table-member > tbody > tr:hover {
  background-color: #fff;
}

.table-member > tbody > tr > th,
.table-member > tbody > tr > td {
  border-color: #fff;
  vertical-align: middle;
  padding: 15px 8px;
}

.table-member > tbody > tr > th {
  font-weight: normal;
  text-align: left;
}

.table-member > tbody > tr > td {
  border-right: 1px solid #fff;
}

.table-member > tbody > tr.collapsing,
.table-member > tbody > tr.collapse {
  background-color: #fff;
}

.table-member .btn-transparent {
  padding: 3px 6px;
}

.table-message,
.table-edit {
  text-align: center;
}

/**
 * ボーダーを使ったテーブル
 * 管理者用、ポップアップのフォームで使う
 */
.table-invitation {
  border-bottom: 1px solid #ccc;
}

.table-invitation > tbody > tr > th,
.table-invitation > tbody > tr > td {
  border-top: 1px solid #ccc;
  border-right: none;
}

.table-invitation > tbody > tr > td.table-edit {
  border-left: 1px solid #ccc;
}

.table-invitation > tbody > tr > td.table-edit + td.table-edit {
  border-left: none;
}

/**
 * フォームに使ったテーブル
 */
.table-form {
  width: 100%;
}

.table-form > tbody > tr > th,
.table-form > tbody > tr > td {
  padding: 7px 5px;
}

.table-form > tbody > tr > th {
  font-weight: normal;
  padding-top: 13px;
  vertical-align: super;
  width: 135px;
}

/**
 * ボーダーとthに背景色
 * ゲスト管理・回答の確認で使う
 */
.table-brand > tbody > tr > th,
.table-brand > tbody > tr > td {
  border: 1px solid #aaa;
  padding: 10px 15px;
  width: 200px;
}

.table-brand > tbody > tr > th {
  background-color: #ee5f62;
  color: #fff;
  font-weight: normal;
  text-align: left;
}

.table-brand > tbody > tr > td {
  text-align: center;
}

/**
 * テーブルを立てに並べる
 */
.table-block {
  display: block;
}

.table-block > thead,
.table-block > thead > tr,
.table-block > thead > tr > th,
.table-block > thead > tr > td,
.table-block > tbody,
.table-block > tbody > tr,
.table-block > tbody > tr > th,
.table-block > tbody > tr > td {
  display: block;
  width: 100%;
}

.table-block.table-invitation {
  margin-bottom: 0;
}

.table-block.table-invitation > tbody > tr > th {
  width: 100%;
}

.table-block.table-invitation > tbody > tr > td {
  border-top: none;
  padding-bottom: 10px;
  width: 100%;
}

.modal-body .table-invitation {
  border-bottom: none;
}

@media screen and (max-width: 767px) {
  .table-sm-block {
    display: block;
  }

  .table-sm-block > thead,
  .table-sm-block > thead > tr,
  .table-sm-block > thead > tr > th,
  .table-sm-block > thead > tr > td,
  .table-sm-block > tbody,
  .table-sm-block > tbody > tr,
  .table-sm-block > tbody > tr > th,
  .table-sm-block > tbody > tr > td {
    display: block;
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .table-member {
    background: #fff;
    text-align: center;
  }

  .table-member > tbody,
  .table-member > tbody > tr,
  .table-member > tbody > tr > th,
  .table-member > tbody > tr > td {
    border: none;
    display: block;
    width: 100%;
  }

  .table-member > thead {
    display: none;
  }

  .table-member > tbody > tr > th,
  .table-member > tbody > tr > td {
    padding: 10px 8px;
  }

  .table-member > tbody > tr {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;
  }

  .table-member > tbody > tr > th {
    border-bottom: 1px solid #ccc;
    background-color: #f4f4f4;
    text-align: left;
  }

  .table-member > tbody > tr > th .label {
    font-size: 1.3rem;
  }

  .table-member > tbody > tr > th + th {
    text-align: center;
    background-color: #fff;
  }

  .table-member > tbody > tr > th.id {
    background: none;
    position: absolute;
    top: 0;
    left: 120px;
    width: auto;
    z-index: 2;
  }

  .table-member > tbody > tr > td + td {
    padding-top: 0;
  }

  .table-member > tbody > tr > td.table-edit {
    border: none;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 0;
    text-align: right;
    width: auto;
    z-index: 3;
  }

  .table-member > tbody > tr > td.table-edit:nth-last-of-type(1) {
    margin-right: 5px;
  }

  .table-member > tbody > tr > td.table-edit:nth-last-of-type(2) {
    margin-right: 31px;
  }

  .table-member > tbody > tr > td.table-edit:nth-last-of-type(3) {
    margin-right: 57px;
  }

  .table-member > tbody > tr.collapse,
  .table-member > tbody > tr.collapsing,
  .table-member > tbody > tr.collapse.in {
    display: none;
    border-top: none;
    background-color: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .table-member > tbody > tr.collapsing,
  .table-member > tbody > tr.collapse.in {
    display: block;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  .table-member > tbody > tr.collapsing[aria-expanded="false"] {
    border: none;
  }
}

.panel {
  border-radius: 5px;
}

.panel-title {
  font-weight: normal;
  padding: 10px 0;
}

.panel-brand {
  border: 1px solid #ee5f62;
}

.panel-attention {
  border: 1px solid #ff2929;
}

.panel-title-toggle {
  cursor: pointer;
  position: relative;
  padding-right: 5em;
}

.panel-title-toggle:before,
.panel-title-toggle:after {
  position: absolute;
  font-weight: normal;
  top: 50%;
  margin-top: -0.5em;
}

.panel-title-toggle:before {
  content: "閉じる";
  right: 1em;
}

.panel-title-toggle:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d8";
  right: 0;
}

.panel-title-toggle.collapsed:before {
  content: "開く";
}

.panel-title-toggle.collapsed:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f0d7";
}

/**
 * タブ
 */
.nav-tabs {
  border-color: #ccc;
}

.nav-tabs > li > a {
  border: 1px solid #ccc;
  color: #aaa;
  background-color: #eee;
  text-decoration: none;
}

.nav-tabs > li > a:active,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: #ccc;
  background-color: #e1e1e1;
}

.nav-tabs-pages {
  margin-bottom: 30px;
}

.nav-tabs-pages > li {
  margin-left: 5px;
}

.nav-tabs-pages > li > a {
  padding: 17px 25px;
}

.nav-tabs-dashboard > li {
  float: right;
}

.nav-tabs-alert {
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-color: #ff2929;
  display: block;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 10px;
  z-index: 2;
}

@media screen and (max-width: 991px) {
  .nav-tabs-pages > li > a {
    padding: 15px 10px;
  }

  .nav-tabs-alert {
    top: 5px;
    right: 5px;
  }
}

@media screen and (max-width: 767px) {
  .nav-tabs-pages {
    margin-bottom: 30px;
    border: none;
  }

  .nav-tabs-pages > li {
    float: none;
    margin: 0;
  }

  .nav-tabs-pages > li > a {
    border-radius: 0;
  }

  .nav-tabs-pages > li:first-child > a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .nav-tabs-pages > li:last-child > a {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .nav-tabs-pages > li.active > a {
    border-bottom: 1px solid #ccc;
  }

  .nav-tabs-pages > li.active > a:active,
  .nav-tabs-pages > li.active > a:hover,
  .nav-tabs-pages > li.active > a:focus {
    border-bottom: 1px solid #ccc;
  }

  .nav-tabs-pages > li > a:after {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    content: "\f105";
    font-size: 20px;
    margin-top: -0.5em;
    position: absolute;
    right: 10px;
    top: 50%;
  }

  .nav-tabs-alert {
    display: inline-block;
    position: relative;
    margin-left: 5px;
    top: -5px;
    right: 0;
  }
}

.well-url {
  display: inline-block;
  max-width: 500px;
  margin: 0 5px 10px 5px;
  width: 100%;
}

/*
* メニューボタン
*/
@media screen and (max-width: 767px) {
  .navbar-toggle {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    border-radius: 3px;
    color: #212121;
    cursor: pointer;
    display: inline-block;
    float: right;
    font-size: 0.9rem;
    height: 50px;
    padding-top: 7px;
    padding-right: 8px;
    padding-left: 8px;
    text-align: center;
    -ms-transition: -ms-transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: background 0.3s ease-out;
    width: 50px;
  }

  .navbar-toggle > span.bar-top,
  .navbar-toggle > span.bar-mid,
  .navbar-toggle > span.bar-bot {
    background-color: #212121;
    display: block;
    height: 2px;
    margin: 4px auto;
    position: relative;
    -ms-transition: -ms-transform 0.3s ease-out;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    width: 20px;
  }

  .navbar-toggle > span.navbar-menu {
    display: block;
  }

  .navbar-toggle > span.navbar-menu:before {
    content: "MENU";
  }

  .navbar-toggle.active > span {
    -webkit-transform-origin: 2px;
    transform-origin: 2px;
    left: 2px;
  }

  .navbar-toggle.active > span.bar-top {
    -webkit-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }

  .navbar-toggle.active > span.bar-mid {
    width: 0;
  }

  .navbar-toggle.active > span.bar-bot {
    -webkit-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }

  .navbar-toggle.active > span.navbar-menu:before {
    content: "CLOSE";
  }

  .ie8 .navbar-toggle.active > span.bar-mid {
    width: 20px;
  }
}

/**
 * ブロック単位のスタイル
 */
.pager a {
  background-color: #555;
  border-radius: 5px;
  border: none;
  color: #fff;
  display: inline-block;
  max-width: 300px;
  margin: 2px;
  padding: 10px;
  position: relative;
  text-decoration: none;
  width: 45%;
}

.pager a:active,
.pager a:hover,
.pager a:focus {
  background-color: #212121;
  color: #fff;
}

.pager-prev:before,
.pager-next:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 25px;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
}

.pager-prev:before {
  content: "\f104";
  left: 15px;
}

.pager-next:after {
  content: "\f105";
  right: 15px;
}

@media screen and (max-width: 640px) {
  .pager a {
    width: auto;
  }

  .pager-prev:before,
  .pager-next:after {
    display: none;
  }
}

/**
* ダッシュボードでのメニューナビゲーション
* ショートカットメニューなど
*/
.menu-group {
  margin-bottom: 30px;
}

.menu-group > .menu-btn .fa {
  margin-right: 5px;
  vertical-align: middle;
  font-size: 40px;
  font-weight: lighter;
}

@media screen and (max-width: 991px) {
  .menu-group > .menu-btn .fa {
    font-size: 24px;
  }
}

@media screen and (max-width: 882px) {
  .menu-group > .menu-btn .fa {
    font-size: 20px;
  }
}

.menu-group > .menu-btn {
  display: block;
  overflow: hidden;
}

.menu-group > .menu-btn .menu-icon {
  width: 40px;
}

.menu-group > .menu-btn + .menu-btn {
  margin-top: -1px;
}

.menu-group > .menu-btn > .btn {
  font-size: 1.9rem;
  border-radius: 0;
  float: left;
  height: 140px;
  padding: 52px 30px 0 30px;
  position: relative;
  text-align: left;
  text-decoration: none;
  width: 50%;
}

.menu-group > .menu-btn > .btn + .btn {
  margin-left: -1px;
}

.menu-group > .menu-btn > .btn:after {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f105";
  font-size: 30px;
  margin-top: -0.5em;
  position: absolute;
  right: 20px;
  top: 50%;
}

.menu-group > .menu-btn .badge {
  font-size: 1.8rem;
  border-radius: 100%;
  line-height: 2;
  padding: 0;
  width: 2em;
}

.menu-group > .menu-btn:first-child > .btn:first-child {
  border-top-left-radius: 5px;
}

.menu-group > .menu-btn:first-child > .btn:last-child {
  border-top-right-radius: 5px;
}

.menu-group > .menu-btn:last-child > .btn:first-child {
  border-bottom-left-radius: 5px;
}

.menu-group > .menu-btn:last-child > .btn:last-child {
  border-bottom-right-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .menu-group > .menu-btn > .btn:after {
    right: 5px;
  }
}

@media screen and (max-width: 991px) {
  .menu-group > .menu-btn > .btn {
    font-size: 1.8rem;
    padding: 46px 10px 0 10px;
    height: 120px;
  }

  .menu-group > .menu-btn > .btn > .menu-icon {
    height: auto;
    width: 24px;
  }

  .menu-group > .menu-btn .badge {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 882px) {
  .menu-group > .menu-btn > .btn {
    font-size: 1.5rem;
    padding: 32px 5px 0 5px;
    height: 90px;
  }

  .menu-group > .menu-btn > .btn > .menu-icon {
    width: 20px;
  }

  .menu-group > .menu-btn > .btn:after {
    font-size: 20px;
  }

  .menu-group > .menu-btn .badge {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 640px) {
  .menu-group > .menu-btn > .btn {
    border-radius: 0;
    height: auto;
    padding: 15px;
    width: 100%;
  }

  .menu-group > .menu-btn > .btn + .btn {
    margin: -1px 0 0 0;
  }

  .menu-group > .menu-btn:first-child > .btn:last-child,
  .menu-group > .menu-btn:last-child > .btn:first-child {
    border-radius: 0;
  }

  .menu-group > .menu-btn:first-child > .btn:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .menu-group > .menu-btn:last-child > .btn:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

/**
* ショートカットメニュー
*/
.menu-shortcut {
  margin-bottom: 0;
}

.menu-shortcut > li {
  display: inline-block;
  vertical-align: top;
  width: 49%;
}

@media screen and (max-width: 882px) {
  .menu-shortcut > li {
    width: 100%;
  }
}

/**
 * デザインの設定・変更でつかったスタイル
 */
.design-item {
  display: inline-block;
  max-width: 300px;
  margin-right: 5px;
  margin-bottom: 30px;
  text-align: center;
  width: 32%;
  vertical-align: top;
}

.design-thumbnail {
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  max-height: 320px;
  margin: -5px 0 15px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.design-thumbnail > img {
  position: relative;
  left: 50%;
  vertical-align: bottom;
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.design-thumbnail-edit {
  height: 220px;
  max-width: 320px;
}

.design-thumbnail-edit > img {
  max-width: inherit;
}

.ie8 .design-thumbnail > img {
  left: 0;
}

.design-preview {
  background-color: rgba(238, 95, 98, 0.5);
  border: none;
  border-radius: 100%;
  color: #fff;
  font-size: 46px;
  height: 1.8em;
  left: 50%;
  line-height: 1.8;
  margin-top: -1.3em;
  margin-left: -0.9em;
  outline: none;
  position: absolute;
  top: 50%;
  width: 1.8em;
}

.design-preview:active,
.design-preview:hover,
.design-preview:focus {
  color: #fff;
  background-color: rgba(238, 95, 98, 0.75);
}

.design-name,
.design-upload {
  font-size: 1.4rem;
  bottom: 0;
  color: #fff;
  left: 0;
  line-height: 1.1;
  margin: 0;
  padding: 13px 10px;
  position: absolute;
  width: 100%;
}

.design-name {
  background-color: #aaa;
  background-color: rgba(170, 170, 170, 0.8);
  font-weight: bold;
}

.design-upload {
  background-color: #111;
  background-color: rgba(17, 17, 17, 0.8);
  text-decoration: none;
  cursor: pointer;
}

.design-upload input {
  width: 0;
  height: 0;
  overflow: hidden;
}

.design-upload:active,
.design-upload:hover,
.design-upload:focus {
  color: #ccc;
}

.design-item.active .design-thumbnail {
  border: 5px solid #ee5f62;
}

.design-item.active .design-name {
  background-color: #ee5f62;
  background-color: rgba(238, 95, 98, 0.8);
}

.design-cover .design-thumbnail {
  height: 200px;
}

.design-cover .design-thumbnail > img {
  height: 200px;
  width: auto;
  max-width: inherit;
}

@media screen and (max-width: 991px) {
  .design-item {
    width: 31%;
  }

  .design-preview {
    font-size: 36px;
  }
}

@media screen and (max-width: 640px) {
  .design-item {
    margin-right: 0;
    width: 48%;
  }

  .design-preview {
    font-size: 28px;
  }
}

/**
 * お知らせのスタイル
 */
.information-attention {
  color: #ff2929;
  margin: 0;
  padding-left: 10em;
  position: relative;
}

.information-attention > dt {
  font-weight: bold;
  position: absolute;
  left: 0;
  top: 0;
}

.information-attention a {
  color: #ff2929;
}

.information-block {
  padding: 0;
}

.information-block > dt {
  margin-bottom: 10px;
  position: static;
}

@media screen and (max-width: 640px) {
  .information-attention {
    padding: 0;
  }
  .information-attention > dt {
    margin-bottom: 10px;
    position: static;
  }
}

/**
 * テーブルの上にある、表記条件部分
 */
.filter-menu {
  overflow: hidden;
  margin-bottom: 10px;
}

.filter-pager {
  float: right;
  margin-top: 10px;
}

.filter-prev,
.filter-next {
  display: inline-block;
  border: 1px solid #aaa;
  color: #aaa;
  font-size: 25px;
  height: 1.2em;
  line-height: 1;
  margin: 3px;
  text-align: center;
  width: 1.2em;
  vertical-align: middle;
}

a.filter-prev,
a.filter-next {
  border-color: #212121;
  color: #212121;
}

.filter-next {
  margin-left: 0;
  margin-right: 0;
}

/**
 * margin
 */
.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-1em {
  margin-bottom: 1em;
}

.mb-2em {
  margin-bottom: 2em;
}

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.fl-box.fl-l {
  margin-right: 20px;
}

.fl-box.fl-r {
  margin-left: 20px;
}

@media screen and (min-width: 992px) {
  .fl-md-l {
    float: left;
  }

  .fl-md-r {
    float: right;
  }

  .fl-box.fl-md-l {
    margin-right: 20px;
  }

  .fl-box.fl-md-r {
    margin-left: 20px;
  }
}

@media screen and (min-width: 768px) {
  .fl-sm-l {
    float: left;
  }

  .fl-sm-r {
    float: right;
  }

  .fl-box.fl-sm-l {
    margin-right: 20px;
  }

  .fl-box.fl-sm-r {
    margin-left: 20px;
  }
}

/**
* 汎用スタイル
*/
.overflow {
  overflow: hidden;
}

.inlineblock {
  display: inline-block;
}

.pc .opa:hover {
  opacity: 0.75;
}

.txt-base {
  font-size: 1.4rem;
  font-weight: normal;
}

.fz-xs {
  font-size: 1rem;
}

.fz-s {
  font-size: 1.2rem;
}

.fz-m {
  font-size: 1.6rem;
}

.fz-l {
  font-size: 1.8rem;
}

.fz-xl {
  font-size: 2rem;
}

.note {
  font-size: 1.2rem;
}

.bold {
  font-weight: bold;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.vat {
  vertical-align: top;
}

.vam {
  vertical-align: middle;
}

.vab {
  vertical-align: top;
}

.relative {
  position: relative;
}

.color-base {
  color: #212121;
}

.color-brand {
  color: #ee5f62;
}

.color-attention {
  color: #ff2929;
}

.color-sinrou {
  color: #0292b0;
}

.color-sinpu {
  color: #ee5f62;
}

.red {
  color: red;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.disabled,
.disabled a {
  color: #bbb;
}

.disabled:active,
.disabled:hover,
.disabled:focus,
.disabled a:active,
.disabled a:hover,
.disabled a:focus {
  color: #bbb;
}

a.color-brand:active,
a.color-brand:hover,
a.color-brand:focus {
  color: #ee5f62;
}

a.color-attention:active,
a.color-attention:hover,
a.color-attention:focus {
  color: #ff2929;
}

.bgc-brand {
  background-color: #ee5f62;
}

.none {
  display: none;
}

.link-ikaryaku {
  word-break: break-word;
}

@media screen and (max-width: 991px) {
  .md-center {
    text-align: center;
  }

  .md-block {
    display: block;
  }

  .md-none {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .sp-center {
    text-align: center;
  }

  .sp-block {
    display: block;
  }

  .sp-none {
    display: none;
  }
}

.header {
  background-color: #f4f4f4;
  border-bottom: 1px solid #ddd;
  min-height: 65px;
  margin: 0;
}

.header-brand {
  padding: 5px 5px 5px 15px;
  margin: 0;
  height: 65px;
}

.header-brand img {
  width: 155px;
}

.header-logout,
.header-preview,
.header-registration {
  border: none;
  display: inline-block;
  line-height: 65px;
  height: 65px;
  text-decoration: none;
  vertical-align: middle;
}

.header-logout,
.header-registration {
  background: none;
  color: #212121;
  padding: 0 30px;
}

.header-logout:active,
.header-logout:hover,
.header-logout:focus,
.header-registration:active,
.header-registration:hover,
.header-registration:focus {
  color: #ee5f62;
}

.header-preview {
  background-color: #ee5f62;
  color: #fff;
  padding: 0 15px;
}

.header-preview:active,
.header-preview:hover,
.header-preview:focus {
  background-color: #e93135;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .header-right {
    display: none;
  }
}

.side {
  border-right: 1px solid #ddd;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  padding-top: 66px;
  width: 240px;
}

.side-navbar {
  background-color: #fff;
  height: 100%;
  overflow: auto;
}

.side-nav > li {
  border-bottom: 1px solid #ddd;
  background-repeat: no-repeat;
  background-position: 15px center;
  -webkit-background-size: 20px auto;
  background-size: 20px auto;
  margin: 0;
  position: relative;
  z-index: 7;
}

.side-nav > li > span,
.side-nav > li > a {
  display: block;
  padding: 22px 5px 20px 45px;
  text-decoration: none;
}

.side-nav > li > a {
  color: #212121;
}

.side-nav > li > a:active,
.side-nav > li > a:hover,
.side-nav > li > a:focus {
  color: #ee5f62;
}

.side-nav > li.current a {
  color: #ee5f62;
}

.side-nav > li.disabled {
  pointer-events: none;
}

.side-nav > li.disabled a {
  color: #aaa;
}

.side-nav .fa {
  position: absolute;
  font-size: 18px;
  left: 16px;
  top: 50%;
  margin-top: -0.5em;
}

.side-nav .badge {
  background-color: #ff2929;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  height: 2em;
  line-height: 2;
  padding: 0;
  text-align: center;
  width: 2em;
}

.side-nav > li:before {
  content: "";
  height: 0;
  width: 0;
}

.side-home {
  background-image: url("../images/common/icon_home.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-home {
    background-image: url("../images/common/icon_home.svg");
  }
}

.side-home:before,
.side-home:active,
.side-home:hover,
.side-home:focus,
.side-home.current {
  background-image: url("../images/common/icon_home_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-home:before,
  .side-home:active,
  .side-home:hover,
  .side-home:focus,
  .side-home.current {
    background-image: url("../images/common/icon_home_on.svg");
  }
}

.side-home.disabled {
  background-image: url("../images/common/icon_home_off.png");
}

.side-edit {
  background-image: url("../images/common/icon_setting.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-edit {
    background-image: url("../images/common/icon_setting.svg");
  }
}

.side-edit:before,
.side-edit:active,
.side-edit:hover,
.side-edit:focus,
.side-edit.current {
  background-image: url("../images/common/icon_setting_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-edit:before,
  .side-edit:active,
  .side-edit:hover,
  .side-edit:focus,
  .side-edit.current {
    background-image: url("../images/common/icon_setting_on.svg");
  }
}

.side-edit.disabled {
  background-image: url("../images/common/icon_setting_off.png");
}

.side-design {
  background-image: url("../images/common/icon_design.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-design {
    background-image: url("../images/common/icon_design.svg");
  }
}

.side-design:before,
.side-design:active,
.side-design:hover,
.side-design:focus,
.side-design.current {
  background-image: url("../images/common/icon_design_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-design:before,
  .side-design:active,
  .side-design:hover,
  .side-design:focus,
  .side-design.current {
    background-image: url("../images/common/icon_design_on.svg");
  }
}

.side-design.disabled {
  background-image: url("../images/common/icon_design_off.png");
}

.side-send {
  background-image: url("../images/common/icon_email.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-send {
    background-image: url("../images/common/icon_email.svg");
  }
}

.side-send:before,
.side-send:active,
.side-send:hover,
.side-send:focus,
.side-send.current {
  background-image: url("../images/common/icon_email_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-send:before,
  .side-send:active,
  .side-send:hover,
  .side-send:focus,
  .side-send.current {
    background-image: url("../images/common/icon_email_on.svg");
  }
}

.side-send.disabled {
  background-image: url("../images/common/icon_email_off.png");
}

.side-invitations {
  background-image: url("../images/common/icon_email.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-invitations {
    background-image: url("../images/common/icon_email.svg");
  }
}

.side-invitations:before,
.side-invitations:active,
.side-invitations:hover,
.side-invitations:focus,
.side-invitations.current {
  background-image: url("../images/common/icon_email_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-invitations:before,
  .side-invitations:active,
  .side-invitations:hover,
  .side-invitations:focus,
  .side-invitations.current {
    background-image: url("../images/common/icon_email_on.svg");
  }
}

.side-invitations.disabled {
  background-image: url("../images/common/icon_email_off.png");
}

.side-guest {
  background-image: url("../images/common/icon_guest.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-guest {
    background-image: url("../images/common/icon_guest.svg");
  }
}

.side-guest:before,
.side-guest:active,
.side-guest:hover,
.side-guest:focus,
.side-guest.current {
  background-image: url("../images/common/icon_guest_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-guest:before,
  .side-guest:active,
  .side-guest:hover,
  .side-guest:focus,
  .side-guest.current {
    background-image: url("../images/common/icon_guest_on.svg");
  }
}

.side-guest.disabled {
  background-image: url("../images/common/icon_guest_off.png");
}

.side-game {
  background-image: url("../images/common/icon_star.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-game {
    background-image: url("../images/common/icon_star.svg");
  }
}

.side-game:before,
.side-game:active,
.side-game:hover,
.side-game:focus,
.side-game.current {
  background-image: url("../images/common/icon_star_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-game:before,
  .side-game:active,
  .side-game:hover,
  .side-game:focus,
  .side-game.current {
    background-image: url("../images/common/icon_star_on.svg");
  }
}

.side-game.disabled {
  background-image: url("../images/common/icon_star_off.png");
}

.side-invitation {
  background-image: url("../images/common/icon_email.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-invitation {
    background-image: url("../images/common/icon_email.svg");
  }
}

.side-invitation:before,
.side-invitation:active,
.side-invitation:hover,
.side-invitation:focus,
.side-invitation.current {
  background-image: url("../images/common/icon_email_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-invitation:before,
  .side-invitation:active,
  .side-invitation:hover,
  .side-invitation:focus,
  .side-invitation.current {
    background-image: url("../images/common/icon_email_on.svg");
  }
}

.side-invitation.disabled {
  background-image: url("../images/common/icon_email_off.png");
}

.side-venue {
  background-image: url("../images/common/icon_marker.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-venue {
    background-image: url("../images/common/icon_marker.svg");
  }
}

.side-venue:before,
.side-venue:active,
.side-venue:hover,
.side-venue:focus,
.side-venue.current {
  background-image: url("../images/common/icon_marker_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-venue:before,
  .side-venue:active,
  .side-venue:hover,
  .side-venue:focus,
  .side-venue.current {
    background-image: url("../images/common/icon_marker_on.svg");
  }
}

.side-venue.disabled {
  background-image: url("../images/common/icon_marker_off.png");
}

.side-report {
  background-image: url("../images/common/icon_list.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-report {
    background-image: url("../images/common/icon_list.svg");
  }
}

.side-report:before,
.side-report:active,
.side-report:hover,
.side-report:focus,
.side-report.current {
  background-image: url("../images/common/icon_list_on.png");
}

@media all and (-webkit-min-device-pixel-ratio: 2) {
  .side-report:before,
  .side-report:active,
  .side-report:hover,
  .side-report:focus,
  .side-report.current {
    background-image: url("../images/common/icon_list_on.svg");
  }
}

.side-report.disabled {
  background-image: url("../images/common/icon_list_off.png");
}

.side-wedding-hall {
  background-image: url("../images/common/icon_wedding_chapel.png");
}

.side-wedding-hall:before,
.side-wedding-hall:active,
.side-wedding-hall:hover,
.side-wedding-hall:focus,
.side-wedding-hall.current {
  background-image: url("../images/common/icon_wedding_chapel_on.png");
}

.side-wedding-hall.disabled {
  background-image: url("../images/common/icon_wedding_chapel_off.png");
}

.side-preview {
  font-size: 1.2rem;
  background-color: #ee5f62;
}

.side-preview:active,
.side-preview:hover,
.side-preview:focus {
  background-color: #e93135;
}

.side-nav > li.side-preview > a {
  color: #fff;
}

.side-nav > li.side-preview > a:active,
.side-nav > li.side-preview > a:hover,
.side-nav > li.side-preview > a:focus {
  color: #fff;
}

@media screen and (max-width: 767px) {
  .side {
    -webkit-transition: right 0.25s ease-out;
    -ms-transition: right 0.25s ease-out;
    transition: right 0.25s ease-out;
    border-right: none;
    border-left: 1px solid #ddd;
    box-shadow: 0 0 5px rgba(51, 51, 51, 0.4);
    position: fixed;
    right: -260px;
    left: auto;
    height: 100%;
    width: 260px;
    z-index: 5;
  }

  .side-nav > li {
    background-position: 10px center;
  }

  .side-nav > li > a,
  .side-nav > li > span {
    padding-left: 40px;
  }

  .side-nav .fa {
    font-size: 1.6rem;
    left: 12px;
  }

  .side.active {
    right: 0;
  }

  .side.active .side-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
  }
}

.main {
  max-width: 1170px;
  margin: 0 auto 50px;
  padding-left: 255px;
  padding-right: 15px;
  padding-bottom: 50px;
}

@media screen and (max-width: 767px) {
  .main {
    padding-left: 15px;
  }
}

.footer {
  background-color: #333;
  overflow: hidden;
  padding: 19px 15px;
  position: relative;
  z-index: 4;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:active,
.footer a:hover,
.footer a:focus {
  text-decoration: underline;
}

.footer-navbar {
  float: right;
}

.footer-nav {
  overflow: hidden;
}

.footer-nav > li {
  border-left: 1px solid #fff;
  float: left;
  padding: 0 10px;
}

.footer-nav > li:first-child {
  border-left: none;
}

.footer-nav > li > a {
  display: inline-block;
  padding: 0 5px;
}

.copyright {
  color: #fff;
  float: left;
}

@media screen and (min-width: 1200px) {
  .footer .container {
    padding-left: 240px;
  }
}

.login {
  background: #f9f9f9;
}

.login-header {
  background: url("../images/login/bg_ribbon.png") no-repeat left top;
  text-align: center;
}

.login-header img {
  width: 80px;
}

.login-logo {
  margin: 0;
  padding: 60px 30px 0;
}

.login-panel {
  border: 1px solid #ccc;
  max-width: 560px;
  margin: 0 auto 100px;
  padding: 60px 30px;
}

.login-panel p {
  margin-bottom: 30px;
}

.login-heading {
  text-align: center;
  margin: 0 0 30px;
  font-weight: normal;
}

.password-reset-heading {
  margin: 0 0 30px;
  font-weight: normal;
}

@media screen and (max-width: 640px) {
  .login-header {
    background-position: -20px -20px;
  }
}

/**
 * 印刷用
 */
@media print {
  .print-none {
    display: none !important;
  }

  .main {
    padding-left: 15px;
  }

  .side {
    display: none;
  }

  .heading-page {
    padding-left: 0;
  }

  .heading-page:before {
    display: none;
  }

  .table-member > thead > tr,
  .table-member > thead > tr > th,
  .table-member > thead > tr > td,
  .table-member > tbody > tr,
  .table-member > tbody > tr > th,
  .table-member > tbody > tr > td {
    border: 1px solid #000;
  }
  .table-member tr.collapse {
    display: table-row;
  }
}
